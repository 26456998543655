<template>
    <div class="d-flex flex-column pa-3" v-if="isShow">
        <div class="item pt-2 pb-2 d-flex flex-row align-center" v-for="(item, i) in list" :key="i" @click="selectedItem(item)">
            <input type="checkbox" :checked="getCheck(item)" class="remind"/>
            <div class="type ml-2" :class="getCheck(item)?'type-selected':''">
                {{item.title || item.city}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'checked-group-app',
        props: {
            isShow: {
                type: Boolean,
                default: false
            },
            list: {
                type: Array,
                default: []
            },
            sList: {
                type: Array,
                default: []
            }
        },
        data(){
            return {
                selectedList: []
            }
        },
        watch: {
            sList(val){
                this.selectedList = val
                console.log('--11-----', this.list, val)
            },
            isShow(val){
                if (val) {
//                    this.selectedList = this.sList
                    console.log(this.selectedList)
                }
            }
        },
        methods: {
            getCheck(item){
                var isSelected = false
                this.sList.forEach(res => {
                    if (res.id == item.id) {
                        isSelected = true
                    }
                })
                return isSelected;
            },
            selectedItem(e){
                this.$emit('selectCallBack', e)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .item {
        width: 150px;
        input[type='checkbox'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: none;
            width: 16px;
            height: 16px;
            cursor: pointer;
            text-align: center;
            content: '';
            background: transparent !important;
            z-index: 1;
            position: relative;
            border: 2px solid #7E7E7E;
        }
        input[type='checkbox']:after {
            position: absolute;
            top: -2px;
            left: -2px;
            text-align: center;
            width: 16px;
            height: 16px;
        }
        input[type='checkbox']:checked::after {
            content: '\2713';
            background-color: $primary-color;
            color: white;
        }
        .type {
            color: #7E7E7E;
            font-size: 18px !important;
            @media (max-width: $screen-md) {
                font-size: 14px !important;
            }
        }
        .type-selected {
            color: $primary-color;
        }
    }

</style>