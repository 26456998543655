import { render, staticRenderFns } from "./social.vue?vue&type=template&id=49d144ec&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./social.vue?vue&type=script&lang=js&"
export * from "./social.vue?vue&type=script&lang=js&"
import style0 from "./social.vue?vue&type=style&index=0&id=49d144ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d144ec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VHover,VImg})
